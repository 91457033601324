import * as React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import Layout from "../components/layout";
import PageContainer from "../components/page-container";
import OButton from "../components/button";
import Blocks from "../components/content-blocks";
import { ContentfulProject } from "../graphql-types";
import PageHeader from "../components/page-header";
import Title from "../components/title";
import SEO from "../components/seo";

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 50px auto;
`;

const Button = styled(OButton)`
  color: black;
  border: solid 1px black;
  margin: 0 auto;
`;

const Tags = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px auto;
  text-align: center;
  font-family: Fira Sans;
  font-weight: 300;
`;

const Tag = styled.li`
  display: inline-block;

  &:not(:last-child) {
    &:after {
      display: inline-block;
      margin: 0 7px;
      content: "-";
    }
  }
`;

interface Props {
  data: {
    project: ContentfulProject;
  };
}

function getDescription(project: ContentfulProject) {
  const textBlock = project.blocks.find(block =>
    Boolean(block.content && block.content.childMarkdownRemark)
  );

  if (textBlock) {
    return textBlock.content.childMarkdownRemark.excerpt;
  }

  return "";
}

export default ({ data: { project } }: Props) => {
  const hasTags = project.tags;
  const hasBlocks = project.blocks;

  const seo = project.seo || {
    id: "",
    title: project.title,
    description: getDescription(project)
  };

  return (
    <Layout>
      <SEO data={seo} />
      <PageContainer>
        <PageHeader>
          <Title>{project.title}</Title>
          {hasTags && (
            <Tags>
              {project.tags.map(tag => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </Tags>
          )}
        </PageHeader>

        {hasBlocks && <Blocks blocks={project.blocks} />}
        <ButtonContainer>
          <Link to="/contact">
            <Button>Parlons de votre projet →</Button>
          </Link>
        </ButtonContainer>
      </PageContainer>
    </Layout>
  );
};

export const query = graphql`
  query Project($slug: String!) {
    project: contentfulProject(slug: { eq: $slug }) {
      title
      tags
      seo {
        ...SEO
      }
      blocks {
        id
        size
        parallax

        color {
          value
        }
        image {
          fluid(maxWidth: 1400, quality: 80) {
            ...GatsbyContentfulFluid
          }
        }
        content {
          childMarkdownRemark {
            html
            excerpt
          }
        }
        titleColor {
          value
        }
        textColor {
          value
        }
      }
    }
  }
`;
